import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import LazyLoad from "react-lazyload";

import { getRegionBreadcrumbs } from "~/shared/utils";

import styles from "./RegionModal.less";

class RegionItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: props.defaultExpanded,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState((prevState) => ({
            expanded: !prevState.expanded,
        }));

        const { option, onClick } = this.props;
        onClick(option);
    }

    get className() {
        const { option, currentRegion } = this.props;
        const active =
            currentRegion && currentRegion.choice_value === option.choice_value;
        return classNames({
            btn: true,
            [styles.item]: true,
            [styles.active]: active,
        });
    }

    get breadcrumbs() {
        const { option } = this.props;
        const breadcrumbs = getRegionBreadcrumbs({
            region: option,
        });
        if (breadcrumbs.length === 1) return breadcrumbs;
        return breadcrumbs.filter(
            (breadcrumb) => breadcrumb.choice_value !== "global",
        );
    }

    get disableToggle() {
        const { option, children } = this.props;
        const { choice_value } = option;
        return (
            choice_value === "global" ||
            choice_value.startsWith("country_") ||
            !children
        );
    }

    renderBreadcrumbs() {
        return this.breadcrumbs
            .map((breadcrumb) => breadcrumb.display_name)
            .join(" / ");
    }

    renderLogo() {
        const { option } = this.props;
        const { abbr, logo_url: logoUrl } = option;
        const width = 20;
        const height = 20;

        const placeholder = (
            <div
                aria-label="placeholder"
                className={styles.placeholder}
                width={width}
                height={height}
            />
        );

        return (
            <LazyLoad offset={150} once overflow placeholder={placeholder}>
                <img
                    src={logoUrl}
                    width={width}
                    height={height}
                    className={styles.logo}
                    alt={abbr}
                />
            </LazyLoad>
        );
    }

    renderFlatLayout() {
        return (
            <button
                className={this.className}
                onClick={this.onClick}
                type="button"
            >
                {this.renderLogo()}
                <span>{this.renderBreadcrumbs()}</span>
            </button>
        );
    }

    renderToggle() {
        if (this.disableToggle) return null;
        const { expanded } = this.state;
        const className = classNames({
            far: true,
            "u-mr": true,
            "fa-angle-right": !expanded,
            "fa-angle-down": expanded,
        });
        return <i aria-hidden="true" className={className} />;
    }

    renderNestedLayout() {
        const { option } = this.props;

        return (
            <button
                className={this.className}
                onClick={this.onClick}
                type="button"
            >
                {this.renderToggle()}
                {this.renderLogo()}
                {option.display_name}
            </button>
        );
    }

    renderItem() {
        const { layout } = this.props;
        switch (layout) {
            case "flat":
                return this.renderFlatLayout();
            case "nested":
            default:
                return this.renderNestedLayout();
        }
    }

    renderChildren() {
        const { expanded } = this.state;
        const { children } = this.props;
        return expanded || this.disableToggle ? children : null;
    }

    render() {
        return (
            <>
                {this.renderItem()}
                {this.renderChildren()}
            </>
        );
    }
}

RegionItem.defaultProps = {
    children: null,
    defaultExpanded: false,
};

RegionItem.propTypes = {
    currentRegion: PropTypes.objectOf(PropTypes.any).isRequired,
    option: PropTypes.objectOf(PropTypes.any).isRequired,
    onClick: PropTypes.func.isRequired,
    layout: PropTypes.oneOf(["flat", "nested"]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    defaultExpanded: PropTypes.bool,
};

export default RegionItem;
