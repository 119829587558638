import React from "react";
import ReactDOM from "react-dom";

import { initAxios } from "~/shared/utils";

import RegionSelect from "./RegionSelect";

initAxios();

const container = document.getElementById("home-region-select");

ReactDOM.render(<RegionSelect />, container);
